import { useState, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { LocalCart } from '~/types/cart';

const { persistAtom } = recoilPersist();

const DEFAULT_CART_STATE = {
  count: 0,
  items: {
    lineItems: [],
  },
};

type CartState = {
  count: number;
  items: LocalCart;
};

export const cartState = atom<CartState>({
  key: 'cart',
  default: DEFAULT_CART_STATE,
  effects_UNSTABLE: [persistAtom],
});

export const useCartSSR = () => {
  const [isInitial, setIsInitial] = useState(true);
  const [value, setValue] = useRecoilState(cartState);

  useEffect(() => {
    setIsInitial(false);
  }, []);

  return [isInitial ? DEFAULT_CART_STATE : value, setValue] as const;
};
